import React, { useRef } from "react"
import { AnimationLotti } from"../../../SUPPORT/COMPONENT/ANIMATIONS/SERVICES/Services"
import "./jaboImage.css";

interface JamoImageContentProps {
    animationVisible?:boolean
    title?: any;
    imagePath?: string;
    MobileImagePath?: any;
    imageAlt?: string;
    subTitle?: string;
    desc?: string;
    backgroundColor?: string;
    buttonText?: string;
    buttonLink?: string;
    isImageVisible?: boolean;
    isButtonVisible?: boolean;
    isImageVisibel?: boolean;
    animation?: any;
    mediaPositionLeft?: boolean;
    titleHeading?: string;
    homeContactAnimationData?:any;
    containerWidth?:string;
    width?:any;
    height?:any;
    animationLoop?:boolean;
    id?:string
}
export const JamboImageContainer:React.FC<JamoImageContentProps> = (props) => {
    let Heading = <h1>{props.title}</h1>;
    let id:string =""
  switch (props.titleHeading) {
    case 'h2':
      Heading = <h2>{props.title}</h2>;
      break;
    case 'h3':
      Heading = <h3>{props.title}</h3>;
      break;
    case 'h4':
      Heading = <h4>{props.title}</h4>;
      break;
  }   
  switch(props.id){
    case "1":
      id=props.id;
      break
      case "2":
        id=props.id;
        break
  }
    const subAnimationData = {
        animationData:props.homeContactAnimationData,
        mobileView:props.animationVisible,
        id:id
      }    
    return <>
    <div className= {`jambo-container ${!props.animationVisible&&"jambo-left-direction"} ${props.mediaPositionLeft&&"jambo-reverse"} `}style={{width:props.containerWidth,backgroundColor:props.backgroundColor}}>
        <div className="jambo-left">
            <div className="jambo-title-container">
                {Heading}
            </div>
            <div className="jambo-descraption">
                {props.desc}
            </div>
            {props.isButtonVisible && <button className="buttonHeroImage animate__animated animate__bounce">{props.buttonText}</button>}
        </div>
        <div className="jambo-right">
            <AnimationLotti {...subAnimationData} width={props.width} height={props.height}  animationLoop={props.animationLoop}/>
        </div>
    </div>
    </>
}