
import { AnimationLotti } from "../../SUPPORT/COMPONENT/ANIMATIONS/SERVICES/Services"
import { HeroImage } from "../SubComponents/HEROIMAGE/HeroImage"; 
import data from "./ToolsData.json"
import { Link} from 'react-router-dom';
import { Route,Routes} from 'react-router-dom';
export const Tools= () => {
  const Webdesing = {
    title: "What we do, We do the best.",
    desc: "We are Degital engacy and provied best services to our claints",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible:true,
    buttonText:"Our Projects",
   
  
  };
  return (
    <>
      <HeroImage {...Webdesing} animation={<AnimationLotti animationData={data} />} />
      <div className="tools__container">
        <div className="tools__products">
          <div className="title"><Link to="/Tools/DocumentDesign/EditDocument">Documents</Link></div>
        </div>
      </div>
    </>
  );
};
