import React, { useEffect, useRef, useState } from "react";

import {
  Controls,
  Player,
  PlayerEvent,
} from "@lottiefiles/react-lottie-player";
interface animationData {
  animationData?: any;
  mobileView?: any;
  height?: any;
  width?: any;
  animationLoop?: boolean;
  id?: string;
}
export const AnimationLotti: React.FC<animationData> = (props) => {
  const normalview = {
    height: props.id == "2" ? props.mobileView && props.height : "auto",
    width: props.id == "2" ? props.mobileView && props.width : "auto",
  };

  return (
    <Player
      src={props.animationData}
      className="player"
      loop={props.animationLoop}
      autoplay
      speed={0}
      style={{ height: normalview.height, width: normalview.width }}
    ></Player>
  );
};
