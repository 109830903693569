import React from "react";
interface TextProps {
    description?:string,
    headingType?:string,
    mainTitle?:string,
    color?:string
}

export const TextContainer:React.FC<TextProps>= (props) =>{
    let Heading = <h1 className="sercivestext-title" style={{color:props.color}}>{props.mainTitle}</h1>
    switch (props.headingType) {
        case 'h2':
          Heading = <h2 className="sercivestext-title"style={{color:props.color}}>{props.mainTitle}</h2>
          break;
        case 'h3':
          Heading = <h3 className="sercivestext-title"style={{color:props.color}}>{props.mainTitle}</h3>;
          break;
        case 'h4':
          Heading = <h4 className="sercivestext-title"style={{color:props.color}}>{props.mainTitle}</h4>;
          break;
      }   
    return <>
     <div className="servicesText">
        {Heading}
        <div className="sercivestext-Des">{props.description}</div>
      </div></>
}