import React from "react";
import { AnimationLotti } from "../../SUPPORT/COMPONENT/ANIMATIONS/SERVICES/Services";
import { HeroImage } from "../SubComponents/HEROIMAGE/HeroImage";
import data from "./home.json";
import { SubServices } from "../SubComponents/SubServices/SubServises";
import { WebPlanning } from "../SubComponents/WEBPLANNING/WebPlanning";
import { TextContainer } from "../SubComponents/CONTAINERTEXT/ContainerText";
import { JamboImageContainer } from "../SubComponents/JAMBOIMABGE/JamboImageContainer";

interface HomeProps {
  animationVisible: boolean;
}
export const Home: React.FC<HomeProps> = (props) => {
  const Home = {
    title: "Ready to Get Start with Your New Business Website",
    desc: "Discribe your wishes to us for your Business website, get the best services",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: true,
    buttonText: "OUR PROJECT",
    titleHeading: "h1",
    containerHeight: "50vh",
    backgroundColor: "#FFF9F5",
    jamboImageDisplay: props.animationVisible,
    homeContactAnimationData: data,
    animationLoop: true,
    containerWidth: "100%",
  };

  const textDetail = {
    mainTitle: "For CONSULTATION",
    description:
      "You can not decide what exactly you are looking for,don’t worry we're here to help you.",
    headingType: "h2",
  };
  return (
    <>
      {/* <HeroImage
        {...Home}
        animation={
          props.animationVisible && (
            <AnimationLotti {...AnimationData}></AnimationLotti>
          )
        }
      /> */}
      <JamboImageContainer
        animationVisible={props.animationVisible}
        {...Home}
      />
      <SubServices />
      <div className="plannning-container">
        <WebPlanning />
      </div>
      <div className="short-text_Container">
        <TextContainer {...textDetail} />
      </div>
    
    </>
  );
};
