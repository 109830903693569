import React, { useRef, useState } from 'react';
import './HeroImage.css';
interface HeroImageProps {
  title?: any;
  imagePath?: string;
  MobileImagePath?: any;
  imageAlt?: string;
  subTitle?: string;
  desc?: string;
  backgroundColor?: string;
  buttonText?: string;
  buttonLink?: string;
  isImageVisible?: boolean;
  isButtonVisible?: boolean;
  isImageVisibel?: boolean;
  animation?: any;
  mediaPositionLeft?: boolean;
  titleHeading?: string;
  containerHeight?: string;
  jamboImageDisplay?: string;
}

export const HeroImage: React.FC<HeroImageProps> = (props) => {
  const isButton = useRef(props.isButtonVisible)
  let Heading = <h1>{props.title}</h1>;
  switch (props.titleHeading) {
    case 'h2':
      Heading = <h2>{props.title}</h2>;
      break;
    case 'h3':
      Heading = <h2>{props.title}</h2>;
      break;
    case 'h4':
      Heading = <h2>{props.title}</h2>;
      break;
  }
  return (
    <div className="hero">
      <div
        className={props.mediaPositionLeft ? 'hero-content mediaDirection' : 'hero-content'}
        style={{ backgroundColor: props.backgroundColor, minHeight: props.containerHeight}}
      >
        <div className={`hero-left ${props.mediaPositionLeft && 'mediaImageOrAnimation'}`}>
          {Heading}
          <p>{props.desc}</p>
          {isButton.current && <button className="buttonHeroImage animate__animated animate__bounce">{props.buttonText}</button>}
        </div>
        {!props.MobileImagePath && props.isImageVisibel && <div className="hero-right">{<img className="hero-image" src={props.imagePath} alt={props.imageAlt} />}</div>}
        {!props.isImageVisibel && <div className="hero-animation"> {props.animation}</div>}
        <div className="hero-mobile-View">{props.isImageVisibel && <img className="hero-mobile-image" src={props.MobileImagePath} alt={props.imageAlt} />}</div>
      </div>
    </div>
  );
};
