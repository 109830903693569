
import { AnimationLotti } from "../../SUPPORT/COMPONENT/ANIMATIONS/SERVICES/Services"
import { HeroImage } from "../SubComponents/HEROIMAGE/HeroImage"; 
import { Image } from "../SubComponents/Image/Image"; 
import data from "./contactdata.json"
import "./ConactUs.css"
import { JamboImageContainer } from "../SubComponents/JAMBOIMABGE/JamboImageContainer";
import { Form } from "../SubComponents/contactForm/ContactForm";
interface ContactProps {
  animationVisible: boolean;
}
export const Contact:React.FC<ContactProps>= (props) => {
  const Contact = {
    title: "What we do, We do the best.",
    desc: "We are Degital engacy and provied best services to our claints",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible:true,
    buttonText:"Our Projects",
  };
    
  const ContactHeader = {
    title: "If you need help why not write us !",
    desc: "Our costumer is first, if you need help just write us our team will contact your in 24 hours.",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: true,
    buttonText: "OUR PROJECT",
    titleHeading: "h1",
    containerHeight: "50vh",
    backgroundColor: "#FFF9F5",
    jamboImageDisplay: props.animationVisible,
    homeContactAnimationData: data,
    animationLoop: true,
    containerWidth: "100%",
  };
  return (
    <>
    {/* <section>
      <HeroImage {...Contact} animation={<AnimationLotti {...AnimationData} />} />
      </section> */}
      <section>
      <JamboImageContainer
        animationVisible={props.animationVisible}
        {...ContactHeader}
      />
      </section>
      <section className="contact-form">
        <div className="contact-form-Wrapper">
          <div className="contact-form-left"><div className="overflow"></div><Image /></div>
          <div className="contact-form-right"><Form /></div>
        </div>
      </section>
    </>
  );
};
