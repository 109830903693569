import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "animate.css";

import "./Nav.css";
interface NavBarProps {
  MobileView: boolean;
  isMenuActive: (isMenuActive: boolean) => void;
  isLinkedClicked:boolean
}
export const Nav: React.FC<NavBarProps> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [isHomeActive, setHomeActive] = useState("HOME");
  const NavBarData = [
    {
      to: "/",
      title: "HOME",
    },
    {
      to: "/ABOUT",
      title: "ABOUT",
    },
    {
      to: "/SERVICES",
      title: "SERVICES",
    },
    {
      to: "/TOOL",
      title: "TOOL",
    },
    {
      to: "/CONTACT",
      title: "CONTACT",
    },
  ];
  const isClicked = (data: any) => {
    if (!props.MobileView && isActive==false) {
      console.log(props.MobileView);
      
      setIsActive(true);
    }
    else {
      setIsActive(false)
    }
    switch (data.title) {
      case "HOME": {
        setHomeActive("HOME");
        props.isMenuActive(true)
        break;
      }
      case "ABOUT": {
        setHomeActive("ABOUT");
        props.isMenuActive(true)
        break;
      }
      case "SERVICES": {
        setHomeActive("SERVICES");
        props.isMenuActive(true)
        break;
      }
      case "TOOL": {
        setHomeActive("TOOL");
        props.isMenuActive(true)
        break;
      }
      case "CONTACT": {
        setHomeActive("CONTACT");
        props.isMenuActive(true)
        break;
      }
    }
  };
  const eventHandlerManu = () => {
    setIsActive(!isActive);
  };

  const getNevMenu = NavBarData.map((data, index) => {
    return (
      <li className="menu-item" key={index}>
        <Link
          className={
            props.isLinkedClicked?(isHomeActive === data.title ? "nav__link active" : "nav__link"):"nav__link"
          }
          onClick={() => isClicked(data)}
          to={data.to}
        >
          {data.title}
        </Link>
      </li>
    );
  });
  return (
    <nav className="navbar">
      <div className="logo">
        <img
          src="./logo1.png"
          alt="LOGO"
        />
      </div>
      <div className="push-left">
        <button
          id="menu-toggler"
          data-class="menu-active"
          className={isActive ? " hamburger menu-active " : "hamburger"}
          onClick={() => eventHandlerManu()}
        >
          <span className="hamburger-line hamburger-line-top"></span>
          <span className="hamburger-line hamburger-line-middle"></span>
          <span className="hamburger-line hamburger-line-bottom"></span>
        </button>

        <ul
          id="primary-menu"
          className={
            isActive === true && props.MobileView
              ? "menu nav-menu new__menu animate__animated animate__fadeInRight"
              : "menu nav-menu"
          }
        >
          {getNevMenu}
        </ul>
      </div>
    </nav>
  );
};
