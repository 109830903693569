import React from "react";
import { JamboImageContainer } from "../../SubComponents/JAMBOIMABGE/JamboImageContainer";
interface DocumentDesignProps {
    animationVisible:boolean
}

export const DocumentDesign:React.FC<DocumentDesignProps> =(props)=>{
    const ContactHeader = {
        title: "If you need help why not write us !",
        desc: "Our costumer is first, if you need help just write us our team will contact your in 24 hours.",
        isImageVisibel: false,
        mediaPositionLeft: false,
        isButtonVisible: true,
        buttonText: "OUR PROJECT",
        titleHeading: "h1",
        containerHeight: "50vh",
        backgroundColor: "#FFF9F5",
        jamboImageDisplay: props.animationVisible,
        homeContactAnimationData: "",
        animationLoop: true,
        containerWidth: "100%",
      };
return<>
      <section>
      <JamboImageContainer
        animationVisible={props.animationVisible}
        {...ContactHeader}
      />
      </section>
<div className="document__container">
    <div className="document__header">
        <div className="docuemnt__logo">Logo</div>
        <div className="document__contact">Tell:+467761991832</div>
    </div>
    <div className="docuemnt__title"></div>
    <div className="document__footter"></div>
</div>
</>
}