import React from 'react';
import { TextContainer } from '../CONTAINERTEXT/ContainerText';
import './services.css';
interface ServicesItems {
  imagePath?: string;
  title?: string;
  description?: string;

}
interface ServicesDetailData {
  backgroundColor?:string
}
const servicesItems = [
  {
    imagePath: './webdesign1.gif',
    title: 'web design',
    description: 'ZSOFTMEDIA will develop your new business website or web shop, with a modern responsive( mobile user friendly ) and user friendly '
  },
  {
    imagePath: './printer.gif',
    title: 'Digital Marketing',
    description: 'ZSOFTMEDIA will make it possible to find customers for your business using the modern technology of Email Marketing, Video Marking, SEO,Pay  Per click and Social Media.'
  },
  {
    imagePath: './dgm.gif',
    title: 'UI & UX Design',
    description: 'ZSOFTMEDIA will create eye catch design and video content for your business social media.'
  }
];
const textDetail = {
  mainTitle:"ZSOFTMEDIA SERVICES!",
  description:"As a digital full-service agency, we assist our customers and focus on the following core topics:",
  headingType:"h2"
}

const mapServicesData = servicesItems.map((item:ServicesItems,index) => {
  return (
    <React.Fragment key={item.imagePath}>
      <div  className="services__box">
        <figure className="services__icon">
          <img src={item.imagePath} alt="" />
        </figure>
        <div className="services__content">
          <h2 className="services__title">{item.title}</h2>
          <p className="services__description">{item.description}</p>
        </div>
      </div>
    </React.Fragment>
  );
});

export const SubServices: React.FC<ServicesDetailData> = (props) => {
  return (
    <>
      <TextContainer  {...textDetail}/>
      <section className="services" style={{backgroundColor:props.backgroundColor}}>{mapServicesData}</section>
    </>
  );
};
