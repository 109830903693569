import React from 'react';
import { TextContainer } from '../CONTAINERTEXT/ContainerText';
import './planning.css';
interface ServicesItems {
  imagePath?: string;
  title?: string;
  description?: string;
}
const PlanningData = [
  {
    id:"1",
    title: 'CONSULTATION',
    description: 'We will visit you, to get your wishes in a personal conversation and to understand your business flow which will make it easy for us in designing your business website.'
  },
  {
    id:"2",
    title: 'PROPOSAL',
    description: 'You will receive a proposal after the conversation when you accept the proposal then we can start the design and implementation WITH your new business website.'
  },
  {
    id:"3",
    title: 'OVERVIEW',
    description: 'Now it’s the time to present you your first version of your business website design with your wishes.'
  },
  {
    id:"4",
    title: 'RESULT',
    description: 'AFTER reviewing your website and checking that all text and content are verifying your wishes once you approve it then it’s time to deploy your website.'
  }
];
const textDetail = {
  mainTitle:"HOW IT WORK",
  description:"Get your business website just in 4 easy steps!",
  headingType:"h2"
}
const getPlanningData = PlanningData.map((data) => {
  return (
    <React.Fragment key={data.id}>
      <li  className="timeline-box wow fadeInLeft animate__animated animate__backInLeft">
        <i className="icon-layers"></i>
        <p className="timeline-title">{data.title}</p>
        <p className="timeline-details">{data.description}</p>
        <span>{data.id}</span>
      </li>
    </React.Fragment>
  );
});
export const WebPlanning: React.FC = () => {
  return (
    <>
      <section className="steps steps-area">
        <div className='short-text_Container'>
      <TextContainer {...textDetail}/>
      </div>
        <div className="container">
          <ul className="timeline">{getPlanningData}</ul>
        </div>
      </section>
    </>
  );
};
