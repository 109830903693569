import React, { useEffect, useState } from 'react';
import { Route,Routes} from 'react-router-dom';
import {Home } from "./COMPONENT/HOME/Home"
import { AboutUs } from './COMPONENT/ABOUT/AboutUs';
import { OurServices } from './COMPONENT/SERVICES/Services';
import {NavBar} from'./SUPPORT/COMPONENT/NAV/Navigation'
import { Contact } from './COMPONENT/CONTACT/Contact';
import {Tools} from"./COMPONENT/TOOLS/Tools"
import { Footer } from './COMPONENT/SubComponents/FOOTER/Footer';
import homeContact from "./contactus.json";
import { JamboImageContainer } from './COMPONENT/SubComponents/JAMBOIMABGE/JamboImageContainer';
import { DocumentDesign } from './COMPONENT/TOOLS/DocumentDesign/EditDocument';
import { Nav } from './COMPONENT/SubComponents/Nav/Nav';

const App =()=> {
  const [animationVisible,setAnimationVisibe] = useState(false)
  const [MobileView, setMobileView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  function myFunction(matchMedia:any) {
    if (matchMedia.matches) { // If media query matches
      setAnimationVisibe(false)
      setMobileView(true)
    } else {
     setAnimationVisibe(true)
     setMobileView(false)
    }
  }
  const isMenuActive =(isMenuActive:boolean)=>{
    setIsActive(isMenuActive)
    
  }
  const HomeContact = {
    title: "Ready to Get Start with Your New Business Website",
    desc: "Discribe your wishes to us for your Business website, get the best services",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: true,
    buttonText: "Contact us",
    titleHeading: "h3",
    containerHeight: "50vh",
    backgroundColor: "#FFF9F5",
    jamboImageDisplay: animationVisible,
    homeContactAnimationData: homeContact,
    colo:"black"
  };
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};
  useEffect(()=>{
  let matchMedia = window.matchMedia("(max-width: 900px)")
  myFunction(matchMedia) 
  matchMedia.addListener(myFunction) 
},[])
  return (
    <div className="">
      <Nav MobileView={MobileView} isMenuActive={isMenuActive} isLinkedClicked={isActive}/>
      <Routes>
        <Route path='/' element ={<Home animationVisible={animationVisible}  />}></Route>
        <Route path='/ABOUT' element ={<AboutUs animationVisible={animationVisible}/>}></Route>
        <Route path='/SERVICES' element ={<OurServices animationVisible={animationVisible} />}></Route>
        <Route path='/TOOL' element ={<Tools  />}></Route>
        <Route path='/Contact' element ={<Contact animationVisible={animationVisible}/>}></Route>
        <Route path='/Tools/DocumentDesign/EditDocument' element ={<DocumentDesign animationVisible={animationVisible} />}></Route>
        {/* <Route path='/About' element ={<About />}></Route>
        
        <Route path='/Contact' element ={<Contact />}></Route> */}

      </Routes>
      <JamboImageContainer
        animationVisible={animationVisible}
        {...HomeContact}
      />
      <div className='goToTop' onScroll={()=>goToTop()}>

      </div>
      <Footer></Footer>
    </div>
  );
}
export default App;
