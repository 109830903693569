import { TextContainer } from "../SubComponents/CONTAINERTEXT/ContainerText";
import { JamboImageContainer } from "../SubComponents/JAMBOIMABGE/JamboImageContainer";
import data from "./AboutUs.json";
import aboutDtailAnimation from "./aboutDetai;AnimationBusiness.json";
interface aboutUsProps {
  animationVisible: boolean;
}

export const AboutUs: React.FC<aboutUsProps> = (props) => {
  const Webdesing = {
    id: "1",
    title: "About Us",
    desc: "ZSOFTMEDIA consists of a young, dynamic and experienced team and is focused on web design and app design in Vienna.",
    isImageVisibel: false,
    mediaPositionLeft: false,
    isButtonVisible: true,
    buttonText: "Contact us",
    titleHeading: "h2",
    containerHeight: "100%",
    backgroundColor: "#FFF9F5",
    jamboImageDisplay: props.animationVisible,
    homeContactAnimationData: data,
    containerWidth: "100%",
    animationLoop: true,
  };
  const aboutDetail = {
    id: "2",
    title: "MY SHORT STORY !",
    desc: "After a very short time, My success as a startup is customers' trust and help. I am working hard to provide the best services to your door. I take full responsibility for your projects. A combination of passion, commitment, competence and quality make ZSOFTMEDIA the perfect partner for your projects.",
    isImageVisibel: false,
    mediaPositionLeft: true,
    isButtonVisible: true,
    buttonText: "Contact us",
    titleHeading: "h3",
    containerHeight: "50vh",
    backgroundColor: "#ffff",
    jamboImageDisplay: props.animationVisible,
    homeContactAnimationData: aboutDtailAnimation,
    height: "700px",
    width: "700px",
    animationLoop: false,
    containerWidth: "100%",
  };
  const textDetail = {
    mainTitle: "ABOUT ZSOFTMEDIA ",
    description: "",
    headingType: "h2",
  };
  return (
    <>
      <section>
        <JamboImageContainer
          animationVisible={props.animationVisible}
          {...Webdesing}
        />
      </section>
      <section className="short-text_Container">
        <TextContainer {...textDetail} />
      </section>
      <section>
        <JamboImageContainer
          animationVisible={props.animationVisible}
          {...aboutDetail}
        />
      </section>
    </>
  );
};
