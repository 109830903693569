import React from "react";
import "./footer.css"
export const Footer =() =>{
    const fullyear = new Date().getFullYear()
    return( 
    <div className="footer-container">
<div className="footer-copyright ">
ZSOFTMEDIA Copyright © {fullyear} ZSOFTMEDIA - All rights reserved || Designed By: ZSOFTMEDIA 
</div>
</div>)
}